/*---------------------
  Sumbit Section
-----------------------*/

.submit-section {
	padding-top: 0;
	padding-bottom: 90px;
}

.submit-section .section-title {
	text-align: left;
}

.submit-section .section-title h2 {
	margin-bottom: 20px;
}

.submit-section .section-title p {
	font-size: 16px;
}

.submit-section .section-title p.f-para {
	margin-bottom: 20px;
}

.submit-section ol {
	padding: 20px;
}
.submit-section ol li  {
	padding: 5px;
	margin-left: 35px;
}

.submit-pic {
	-webkit-box-shadow: 0px 0px 30px rgba(11, 12, 48, 0.15);
	box-shadow: 0px 0px 30px rgba(11, 12, 48, 0.15);
}

.submit-pic img {
	min-width: 100%;
}

.submit-text {
	padding-top: 20px;
}

.submit-text h3 {
	color: #171822;
	font-weight: 600;
	margin-bottom: 25px;
}

.submit-text p {
	margin-bottom: 20px;
}

.submit-text ul li {
	font-size: 16px;
	color: #6a6b7c;
	line-height: 30px;
	list-style: none;
}

.submit-text ul li span {
	color: #f44949;
}
