/*---------------------
  Home About Section
-----------------------*/

.home-about-section {
	padding-bottom: 90px;
}

.ha-pic {
	-webkit-box-shadow: 0px 0px 30px rgba(11, 12, 48, 0.15);
	box-shadow: 0px 0px 30px rgba(11, 12, 48, 0.15);
	margin-right: 20px;
	min-width: 100%;
}

.ha-text {
	padding-top: 74px;
}

.ha-text h2 {
	color: #171822;
	font-size: 40px;
	font-weight: 700;
	margin-bottom: 32px;
}

.ha-text p {
	margin-bottom: 21px;
}

.ha-text ul {
	margin-bottom: 27px;
}

.ha-text ul li {
	list-style: none;
	font-size: 16px;
	line-height: 30px;
	color: #6a6b7c;
}

.ha-text ul li span {
	color: #f44949;
}

.ha-text .ha-btn {
	font-size: 16px;
	color: #171822;
	font-weight: 600;
	position: relative;
	text-decoration: none;
}

.ha-text .ha-btn:before {
	position: absolute;
	left: 0;
	bottom: -6px;
	height: 2px;
	width: 100%;
	background: #f44949;
	content: "";
}

.ha-pic-and-info {
    display: flex; /* Establishes flex container */
    flex-direction: column; /* Stacks children vertically */
    text-align: left; /* Centers text within the children */
}

.info-label {
    font-weight: bold; /* Makes the label text bold */
    display: inline-block; /* Ensures the label is aligned with its value */
    margin-right: 5px; /* Adds a small space after the label */
}

.additional-text {
	font-weight: bold;
}

.additional-text a {
    color: #0066cc;
    text-decoration: none;
}

.additional-text a:hover {
    text-decoration: underline;
}

.additional-info {
	position: relative;
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    font-size: 14px;
    line-height: 1.6;
    color: #333;
}

.additional-info p {
    margin-bottom: 10px;
    line-height: 1.4; /* Adjust line height for compactness */
}

.additional-info a {
    color: #0066cc;
    text-decoration: none;
}

.additional-info a:hover {
    text-decoration: underline;
}

.by-image {
    position: absolute; /* Absolute position within the relative container */
    top: 5px; /* Distance from the top of the container */
    right: 5px; /* Distance from the right of the container */
    display: block; /* Ensures the link behaves as a block for proper dimensions */
    width: 30px; /* Width of the CC image, adjust as needed */
    height: auto; /* Maintains the aspect ratio of the image */
}

.cc-image {
    position: absolute; /* Absolute position within the relative container */
    top: 5px; /* Distance from the top of the container */
    right: 40px; /* Distance from the right of the container */
    display: block; /* Ensures the link behaves as a block for proper dimensions */
    width: 30px; /* Width of the CC image, adjust as needed */
    height: auto; /* Maintains the aspect ratio of the image */
}

.by-image img {
    width: 100%; /* Ensures the image scales to the width of its container */
    height: auto; /* Maintains the aspect ratio */
}

.cc-image img {
    width: 100%; /* Ensures the image scales to the width of its container */
    height: auto; /* Maintains the aspect ratio */
}