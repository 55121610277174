/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/

html,
body {
	height: 100%;
	font-family: "Work Sans", sans-serif;
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	color: #111111;
	font-weight: 400;
	font-family: "Work Sans", sans-serif;
}

h1 {
	font-size: 70px;
}

h2 {
	font-size: 36px;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 24px;
}

h5 {
	font-size: 18px;
}

h6 {
	font-size: 16px;
}

p {
	font-size: 16px;
	font-family: "Work Sans", sans-serif;
	color: #6a6b7c;
	font-weight: 400;
	line-height: 28px;
	margin: 0 0 15px 0;
}

img {
	max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
	outline: none;
}

a:hover,
a:focus {
	text-decoration: none;
	outline: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/

.section-title {
	text-align: center;
	margin-bottom: 52px;
}

.section-title h2 {
	font-size: 40px;
	font-weight: 700;
	color: #171822;
	margin-bottom: 10px;
}

.section-title p {
	font-size: 18px;
	color: #6a6b7c;
	margin-bottom: 0;
}

.set-bg {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
}

.spad {
	padding-top: 100px;
	padding-bottom: 100px;
}

.spad-2 {
	padding-top: 50px;
	padding-bottom: 70px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: #fff;
}

.bg-gradient,
.bd-text .bd-tag-share .s-share a:hover,
.bh-text .play-btn,
.schedule-table-tab .nav-tabs .nav-item .nav-link.active,
.newslatter-inner .ni-form button,
.latest-item .li-tag,
.price-item .price-btn:hover,
.price-item .pi-price,
.price-item .tr-tag,
.schedule-tab .nav-tabs .nav-item .nav-link.active,
.site-btn {
	background-image: -o-linear-gradient(330deg, #ee8425 0%, #f9488b 100%), -o-linear-gradient(330deg, #ee8425 0%, #f9488b 100%);
	background-image: linear-gradient(120deg, #ee8425 0%, #f9488b 100%), linear-gradient(120deg, #ee8425 0%, #f9488b 100%);
}

/* buttons */

.primary-btn {
	display: inline-block;
	font-size: 16px;
	font-weight: 600;
	padding: 12px 40px;
	color: #ffffff;
	text-align: center;
	border-radius: 50px;
	background-image: -webkit-gradient(linear, left top, right top, from(#ee8425), to(#f9488b)), -webkit-gradient(linear, left top, right top, from(#ee8425), to(#f9488b));
	background-image: -o-linear-gradient(left, #ee8425 0%, #f9488b 100%), -o-linear-gradient(left, #ee8425 0%, #f9488b 100%);
	background-image: linear-gradient(to right, #ee8425 0%, #f9488b 100%), linear-gradient(to right, #ee8425 0%, #f9488b 100%);
}

.site-btn {
	font-size: 16px;
	color: #ffffff;
	font-weight: 600;
	border: none;
	padding: 10px 40px 12px;
	border-radius: 50px;
}

/* ----------------------------------- Other Pages Styles ------------------------------- */

/*---------------------
  Header text appears at top on each page
-----------------------*/

.main-section {
	padding: 75px 0 75px;
}

.main-text {
	text-align: center;
}

.main-text h2 {
	font-size: 40px;
	color: #171822;
	font-weight: 600;
	margin-bottom: 14px;
}

.main-text .bt-option a {
	font-size: 18px;
	color: #6a6b7c;
	display: inline-block;
	margin-right: 25px;
	position: relative;
}

.main-text .bt-option a:after {
	position: absolute;
	right: -17px;
	bottom: 0px;
	content: ">";
	font-family: "FontAwesome";
}

.main-text .bt-option span {
	display: inline-block;
	color: #a0a1b5;
}



/*-------------------------------- Respinsive Media Styles --------------------------------*/

/* Container and CC-text */
@media (min-width: 1200px) {
	.container {
		max-width: 1170px;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.cc-text {
		padding-top: 65px;
	}
}

@media (max-width: 991px) {
	.bd-text .bd-more-pic img {
		margin-bottom: 10px;
	}
	.cc-text {
		padding-left: 40px;
	}
}



.tab {
    display: inline-block;
    margin-left: 4em;
}
