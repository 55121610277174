/*---------------------
  Aims and Scopes Section
-----------------------*/

.aims-and-scopes-section {
	padding-top: 0;
	padding-bottom: 90px;
}

.aims-and-scopes-section .section-title {
	text-align: left;
}

.aims-and-scopes-section .section-title h2 {
	margin-bottom: 20px;
}

.aims-and-scopes-section .section-title p {
	font-size: 16px;
}

.aims-and-scopes-section .section-title p.f-para {
	margin-bottom: 20px;
}

.aims-and-scopes-pic {
	-webkit-box-shadow: 0px 0px 30px rgba(11, 12, 48, 0.15);
	box-shadow: 0px 0px 30px rgba(11, 12, 48, 0.15);
}

.aims-and-scopes-pic img {
	min-width: 100%;
}

.aims-and-scopes-text {
	padding-top: 20px;
}

.aims-and-scopes-text h3 {
	color: #171822;
	font-weight: 600;
	margin-bottom: 25px;
}

.aims-and-scopes-text p {
	margin-bottom: 20px;
}

.aims-and-scopes-text ul li {
	font-size: 16px;
	color: #6a6b7c;
	line-height: 30px;
	list-style: none;
}

.aims-and-scopes-text ul li span {
	color: #f44949;
}