/*---------------------
  Editorial Section
-----------------------*/

.editorial-section {
	padding-top: 0;
}

.editorial-item {
	margin-bottom: 50px;
}

.editorial-section .section-title h3 {
	text-align: left;
}

.editorial-item .si-pic img {
	min-width: 100%;
	border-radius: 2px;
}

.editorial-item .si-text .si-title {
	margin-bottom: 28px;
}

.editorial-item .si-text .si-title h4 {
	color: #171822;
	font-weight: 600;
	margin-bottom: 13px;
}

.editorial-item .si-text .si-title span {
	color: #f44949;
	font-size: 16px;
	display: block;
}

.editorial-item .si-text .si-social {
	margin-bottom: 24px;
}

.editorial-item .si-text .si-social a {
	display: inline-block;
	font-size: 16px;
	color: #171822;
	height: 40px;
	width: 40px;
	text-align: center;
	line-height: 40px;
	background: #ebebeb;
	border-radius: 50%;
	margin-right: 5px;
}

.editorial-item .si-text .si-social a:hover {
	background: -o-linear-gradient(330deg, #ee8425, #f9488b);
	background: linear-gradient(120deg, #ee8425, #f9488b);
	color: #ffffff;
}

.editorial-item .si-text p {
	color: #707070;
	line-height: 26px;
}

.load-more {
	text-align: center;
	padding-top: 10px;
}

.load-more .primary-btn {
	padding: 12px 45px;
}