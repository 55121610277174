/*---------------------
  Header
-----------------------*/

.header-section {
	display: flex;
	flex-direction: column;
    align-items: center;
}

.header-section .header-normal {
	border-bottom: 1px solid #e5e5e5;
}

.header-menu {
	padding-top: 0px;
}

.logo {
	float: left;
	padding-top: 36px;
	padding-bottom: 37px;
}

.logo a {
	display: inline-block;
	vertical-align: middle;
	text-decoration: none;
}

.logo p {
    margin: 0; /* Removes default paragraph margin */
    font-size: 18px; /* Adjust to your preference */
    line-height: 1; /* Reduces the space between lines */
}

.logo .e-isnn {
    font-size: 10px; /* Smaller font size for e_ISNN */
    line-height: 1; /* Keeps line height compact */
}

.logo .e-isnn, .logo .e-isnn:hover {
    text-decoration: none;
    color: gray;
    cursor: default;
}

.nav-menu {
	float: right;
}

.nav-menu .mainmenu {
	display: inline-block;
}

.nav-menu .mainmenu ul {
	padding-left: 0em;
}

.nav-menu .mainmenu ul li {
	display: inline-block;
	list-style: none;
	margin-right: 40px;
	position: relative;
}

.nav-menu .mainmenu ul li .dropdown {
	position: absolute;
	width: 180px;
	background: #ffffff;
	z-index: 99;
	left: 0;
	top: 130px;
	opacity: 0;
	visibility: hidden;
	-webkit-box-shadow: 4px 13px 30px rgba(0, 0, 0, 0.15);
	box-shadow: 4px 13px 30px rgba(0, 0, 0, 0.15);
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.nav-menu .mainmenu ul li .dropdown li {
	display: block;
	margin-right: 0;
}

.nav-menu .mainmenu ul li .dropdown li a {
	padding: 8px 10px;
	display: block;
	transition: all 0.3s;
	text-transform: capitalize;
}

.nav-menu .mainmenu ul li .dropdown li a:hover {
	color: #f44949;
}

.nav-menu .mainmenu ul li .dropdown li a:after {
	display: none;
}

.nav-menu .mainmenu ul li.active a:after {
	opacity: 1;
}

.nav-menu .mainmenu ul li:hover>a:after {
	opacity: 1;
}

.nav-menu .mainmenu ul li:hover .dropdown {
	opacity: 1;
	visibility: visible;
	top: 100px;
}

.nav-menu .mainmenu ul li a {
	font-size: 16px;
	color: #171822;
	text-transform: uppercase;
	text-decoration: none;
	font-weight: 600;
	padding: 42px 0;
	position: relative;
	display: inline-block;
}

.nav-menu .mainmenu ul li a:after {
	position: absolute;
	left: 0;
	bottom: 36px;
	height: 2px;
	width: 100%;
	background: #F44949;
	content: "";
	opacity: 0;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
}

.nav-menu .primary-btn.top-btn {
	margin: 27px 0;
}

.header-section .mobile-menu-toggle {
	display: block;
	margin: 34px 0;
	padding-top: 36px;
	border-radius: 0;
	text-decoration: none;
	text-shadow: 0 1px 1px rgba(255,255,255,.75);
	border-radius: 4px;
	background-color: #222;
	color: #ffffff;
	position: relative;
	display: block;
	float: right;
	padding: .438em .625em;
	line-height: 1.125em;
	box-sizing: border-box;
	font-family: "Work Sans", sans-serif;
}

/* Medium Device: 1200px */
@media (min-width: 992px) and (max-width: 1199px) {
	.nav-menu .mainmenu ul li {
		margin-right: 25px;
	}
}

/* Hide menu if screen is large */
@media (min-width: 992px) {
	.header-section .mobile-menu-toggle {
		display: none;
	}
}

@media (max-width: 991px) {
	/* Make the ul behave like a table */
	.nav-menu .mainmenu {
		display: block;
		padding: 0px;
		margin: 0px;
		width: min(90vw, 540px);
	}

	.nav-menu .mainmenu ul {
		display: block;
		width: 100%;
		padding: 0px;
		margin: 0px;
	}

	.nav-menu .mainmenu ul li {
		display: block;
		position: relative;
		text-align: left;
		background-color: #f8f8f8;
		border: 1px solid #ddd;
		cursor: pointer;
		padding: 0px;
		margin: 0px;
		height: 50px;
		text-align: center;
		align-content: center;
	}

	.nav-menu .mainmenu ul li a {
		padding: 0px;
		text-align: center;
	}
	
	/* Dropdown menu styling */
	.nav-menu .mainmenu ul li .dropdown {
		display: block;
		width: 100%;
		background-color: white;
		border: 1px solid #ccc;
		top: 0px;
	}
	
	
	.nav-menu .mainmenu ul li .dropdown ul li {
		border-bottom: 1px solid #ddd;
	}

	.nav-menu .mainmenu ul li .dropdown ul li a {
		padding: 0px;
		margin: 0px;
	}

	.nav-menu .mainmenu ul li hover {
		display: none;
	}

	.nav-menu .mainmenu ul li .dropdown li a:hover {
		display: none;
	}

	.nav-menu .mainmenu ul li:hover>a:after {
		display: none;
	}
}