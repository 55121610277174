/*---------------------
  View Article Section
-----------------------*/

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header .logo img {
    max-height: max(10vw, 100px);
    margin-bottom: 10px;
}

.article-detail {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.article-detail h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.article-detail p {
    font-size: 1em;
    margin-bottom: 10px;
}

.article-detail .author {
    cursor: pointer;
    color: rgba(var(--bs-link-color-rgb));
}

.article-detail .author-detail {
    padding-left: 20px;
}

.article-detail .author-detail .author-mail {
    text-decoration: none;
}

.article-detail .primary-btn {
    display: inline-block;
    font-size: 1em;
    font-weight: 600;
    padding: 10px 20px;
    color: #ffffff;
    background-color: #f44949;
    border-radius: 5px;
    text-decoration: none;
}

.article-detail .primary-btn:hover {
    background-color: #d43b3b;
}

.article-detail .document {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.article-detail .document .pdf {
    height: max(2vw, 30px);
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: right;
    
}

.article-detail .document .pdf img {
    margin-right: 10px;
    height: max(2vw, 30px);
}

.article-detail .document .pdf .icon {
    margin-right: 10px;
    font-size: max(1.5vw, 20px);
    color: #6a6b7c;
}